import { Image } from "@crafthunt-ui/Image";
import { StarsRating } from "@crafthunt-ui/StarsRating";
import { storyblokEditable } from "@storyblok/react";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { CSSProperties } from "react";
import { ImageDef } from "types/image.types";
import { StoryblokLinkDef } from "types/storyblok.types";
import { removeEmpty } from "utils/helpers";
import { getTranslatedSlug } from "utils/router.helper";

type Item = {
  _uid: string;
  image: ImageDef;
  company_logo: ImageDef;
  quote: string;
  author: string;
  profile_link: StoryblokLinkDef | null;
  rating?: string;
};

type Props = {
  blok: {
    _uid: string;
    items: Item[];
    darkTheme?: boolean;
    verticalPadding?: "small" | "medium" | "large";
  };
  className?: string;
  style?: CSSProperties;
};

const Reviews = ({ blok, className = undefined, style = undefined }: Props) => {
  const router = useRouter();

  return (
    <div
      className={clsx("relative isolate", className, {
        "bg-gray-50 text-gray-900": !blok.darkTheme,
        "bg-gray-900 text-white": blok.darkTheme,
        "py-8 sm:py-12": blok.verticalPadding === "small",
        "py-12 sm:py-16": blok.verticalPadding === "medium",
        "py-16 sm:py-24":
          !blok.verticalPadding || blok.verticalPadding === "large",
      })}
      style={style}
      {...storyblokEditable(blok)}
    >
      <div className={"flex flex-wrap justify-center gap-10 sm:gap-x-20"}>
        {blok.items.map((item) => (
          <figure key={item._uid} className="-sm max-w-[260px]">
            <figcaption className="flex flex-col items-center justify-center gap-2 gap-x-4 text-center">
              {!!item.image?.filename && (
                <div className="relative h-10 w-10 flex-none">
                  <Image
                    className="rounded-full bg-gray-50 object-contain"
                    src={item.image.filename}
                    storyblokSize="200x200/smart"
                    storyblokFocusPoint={item.image.focus}
                    alt="Testimonial author"
                  />
                </div>
              )}
              {Boolean(item.rating) && (
                <StarsRating rating={parseFloat(item.rating)} />
              )}
              <blockquote className="font-semibold">
                <p>{`“${item.quote}”`}</p>
              </blockquote>
              {item.author && (
                <div className="flex items-center justify-center">
                  <div className="text-sm font-medium">{item.author}</div>
                  {item.profile_link && (
                    <Link
                      href={{
                        pathname: getTranslatedSlug(item.profile_link),
                        query: removeEmpty({
                          ...router.query,
                          slug: undefined,
                        }),
                      }}
                      className="text-gray-600 hover:text-gray-900"
                    >
                      <span className="pl-2" aria-hidden="true">
                        →
                      </span>
                    </Link>
                  )}
                </div>
              )}
              {item.company_logo?.filename && (
                <div className="relative h-12 w-full flex-none">
                  <Image
                    className="object-contain"
                    src={item.company_logo.filename}
                    storyblokSize="0x200/smart"
                    storyblokAdditionalFilters=":fill(transparent)"
                    storyblokFocusPoint={item.company_logo.focus}
                    alt="Company logo"
                  />
                </div>
              )}
            </figcaption>
          </figure>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
