import { YouTubeEmbed } from "@next/third-parties/google";
import Placeholder from "./Placeholder";
import { SectionHeader } from "@crafthunt-ui/SectionHeader";
import { CSSProperties } from "react";
import clsx from "clsx";

export function Video({
  blok,
  className,
  style,
}: {
  blok: {
    title?: string | null;
    description?: string | null;
    videoLink: string;
  };
  className?: string;
  style?: CSSProperties;
}) {
  let videoId: string | null = null;

  try {
    videoId = new URL(blok.videoLink).searchParams.get("v");
  } catch (error) {
    console.error("Invalid video link", blok.videoLink);
    console.error(error);
  }

  if (!videoId) {
    return <Placeholder componentName="Video" />;
  }

  return (
    <div className={clsx(className, "bg-white py-16")} style={style}>
      {Boolean(blok.title || blok.description) && (
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <SectionHeader label={blok.title} description={blok.description} />
        </div>
      )}
      <div className="[&_lite-youtube]:aspect-ratio-[16/9] mx-auto max-w-md px-4 pt-8 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8 lg:pt-12 [&_lite-youtube]:max-w-full">
        <YouTubeEmbed
          // @ts-expect-error - title is a valid prop
          title={blok.title}
          playlabel={blok.title}
          videoid={videoId}
        />
      </div>
    </div>
  );
}
