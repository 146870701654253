import { ConditionalWrapper } from "@crafthunt-ui/ConditionalWrapper";
import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import { Image } from "@crafthunt-ui/Image";
import { SectionHeader } from "@crafthunt-ui/SectionHeader";
import { storyblokEditable } from "@storyblok/react";
import clsx from "clsx";
import { getFullSlug, getTranslatedSlug } from "utils/router.helper";

const LogoGrid = ({ blok, className = undefined, style = undefined }) => {
  return (
    <div
      style={style}
      className={clsx(
        "relative isolate pb-32 pt-24 sm:pt-24",
        {
          "bg-gray-50 text-gray-900": !blok.darkTheme,
          "bg-gray-900 text-white": blok.darkTheme,
        },
        className
      )}
      {...storyblokEditable(blok)}
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {blok.title && (
          <SectionHeader
            label={blok.pretitle}
            description={blok.title}
            content={blok.subtitle}
            darkTheme={blok.darkTheme}
            align={blok.headerAlign}
          />
        )}
        <div
          className={clsx(
            "mx-auto mt-12 grid max-w-2xl grid-cols-3 gap-0.5 overflow-hidden rounded-md text-sm leading-6 text-gray-900 lg:mx-0 lg:max-w-none lg:grid-cols-4",
            {
              "xl:grid-cols-4": blok.logosPerRow === "4",
              "xl:grid-cols-5": blok.logosPerRow === "5",
              "xl:grid-cols-6": !blok.logosPerRow || blok.logosPerRow === "6",
            }
          )}
        >
          {blok.logos.map((el, index) => (
            <ConditionalWrapper
              key={el.id}
              condition={!!el.source}
              wrapper={(children) => (
                <CustomLink
                  id={`logo-image-${blok._uid}`}
                  href={getFullSlug(el.source)}
                  className="group empty:hidden"
                >
                  {children}
                </CustomLink>
              )}
            >
              <div
                className={clsx(
                  "relative h-20 items-center justify-center transition-colors sm:h-24",
                  {
                    "bg-white group-hover:bg-primary-100": !blok.darkTheme,
                    "bg-white/5 group-hover:bg-white/15": blok.darkTheme,
                    "hidden sm:flex": index >= 18,
                  }
                )}
              >
                <Image
                  className="object-contain p-4 md:p-6"
                  src={el.filename}
                  storyblokSize="0x200"
                  storyblokFocusPoint={el.focus}
                  storyblokQuality={100}
                  alt={el.alt || "Company logo"}
                  sizes="15vw"
                />
              </div>
            </ConditionalWrapper>
          ))}
        </div>
        {/* Call to Action Section */}
        {blok.showCTA && (
          <div className="mx-auto mt-10 max-w-7xl overflow-hidden rounded-md text-left md:max-w-2xl lg:max-w-none">
            <div
              className={clsx({
                "bg-white text-gray-900": !blok.darkTheme,
                "bg-white/5 text-white": blok.darkTheme,
              })}
            >
              <div className="mx-auto px-6 py-5 sm:py-8 lg:flex lg:items-center lg:justify-between lg:px-8">
                <div className="lg:mr-32">
                  {blok.cta_title && (
                    <h2 className="text-xl font-bold tracking-tight sm:text-2xl">
                      {blok.cta_title}
                    </h2>
                  )}
                  {!!blok.cta_subtitle && <span>{blok.cta_subtitle}</span>}
                </div>
                {!!blok.cta_button_label && (
                  <div className="mt-4 flex items-center gap-x-6 lg:mt-0 lg:shrink-0">
                    <CustomLink
                      isCTA
                      id={`cta-logo-grid-${blok._uid}`}
                      href={getTranslatedSlug(blok.cta_button_url, {
                        fromJourney: blok.cta_button_journey,
                      })}
                      target={blok.cta_button_url?.target}
                      className="rounded-sm bg-primary-600 px-3.5 py-2.5 text-lg font-medium text-white shadow-md hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {blok.cta_button_label}
                    </CustomLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LogoGrid;
