import { NavbarCTADef } from "@crafthunt-ui/NavbarCTA/NavbarCTA";
import { useResponsive } from "ahooks";
import { AuthBottomNavigation } from "features/auth/components/AuthBottomNavigation/AuthBottomNavigation";
import { AuthNavigation } from "features/auth/components/AuthNavigation/AuthNavigation";
import { useAuth } from "features/auth/context/AuthContext";
import { TranslatedSlugDef } from "features/translations/types/translation.types";
import { useHasHydrated } from "hooks/useHasHydrated";
import dynamic from "next/dynamic";
import { StoryblokSettingsDef } from "types/storyblok.types";
import { DefaultNavigation } from "./components/DefaultNavigation/DefaultNavigation";

const DynamicBanner = dynamic(
  () => import("components/sections/Banner").then((mod) => mod.Banner),
  {
    ssr: false,
  }
);

type NavigationProps = {
  locale: string;
  settings?: StoryblokSettingsDef;
  translatedSlugs?: TranslatedSlugDef[];
  hideNav?: boolean;
  hideAuthNav?: boolean;
  hideBanner?: boolean;
  customNavbarAction?: NavbarCTADef[];
  isCompanyPage?: boolean;
};

const Navigation = ({
  locale,
  settings,
  translatedSlugs,
  hideNav,
  hideAuthNav,
  hideBanner,
  customNavbarAction,
  isCompanyPage,
}: NavigationProps) => {
  const hasHydrated = useHasHydrated();
  const { loading, isLoggedIn } = useAuth();
  const responsive = useResponsive();

  // only single banner is supported for now
  const banner = !hideBanner && settings?.banners?.[0];

  if (hasHydrated && !hideAuthNav && (loading || isLoggedIn)) {
    return (
      <>
        <AuthNavigation
          settings={settings}
          banners={
            banner
              ? [<DynamicBanner key={banner._uid} blok={banner} />]
              : undefined
          }
        />
        {banner && !responsive.lg && (
          <div className="sticky top-0 z-10">
            <DynamicBanner key={banner._uid} blok={banner} />
          </div>
        )}
        <AuthBottomNavigation />
      </>
    );
  }

  return (
    <DefaultNavigation
      locale={locale}
      settings={settings}
      translatedSlugs={translatedSlugs}
      hideNav={hideNav}
      hideAuthNav={hideAuthNav}
      customNavbarAction={customNavbarAction}
      isCompanyPage={isCompanyPage}
      banners={
        banner ? [<DynamicBanner key={banner._uid} blok={banner} />] : undefined
      }
    />
  );
};

export default Navigation;
