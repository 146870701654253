import { deletes, fetcher, post, put } from "constants/api";
import {
  AuthLoginRequest,
  AuthLoginResponse,
  AuthSignUpRequest,
  AuthUserDef,
  AuthUserResponse,
  UserEditRequest,
} from "../types/auth.types";

export const login = (data: AuthLoginRequest) => {
  return post<AuthLoginResponse>("/auth", data);
};

export const signUp = (data: AuthSignUpRequest) => {
  return post<AuthUserResponse>("/auth", data);
};

export const logout = () => {
  return post("/auth_logout");
};

export const getUserById = (id: string) => {
  return fetcher<AuthUserDef>(`/worker_profiles/info/${id}`);
};

export const editUserById = (id: string, data: UserEditRequest) => {
  return put<AuthUserDef>(`/worker_profiles/info/${id}`, data);
};

export const deleteUserById = ({
  id,
  deletionReasons,
}: {
  id: string;
  deletionReasons: string[];
}) => {
  return deletes<AuthUserDef>(`/worker_profiles/info/${id}`, {
    params: { deletionReasons },
  });
};

export const updateLanguageSetting = (lang: string) => {
  return put<void>(`/worker_profiles/info_settings`, { lang });
};
