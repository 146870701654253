import { apiCached } from "constants/api";
import { ENV } from "constants/env";
import { convertLocaleForBackend } from "utils/language.helper";
import { EGeocodingType, GeocodingResponseDef } from "../types/geocoding.types";

export const reverseGeocode = async (
  lat: number,
  lng: number,
  lang: string
): Promise<GeocodingResponseDef> => {
  const params = {
    lat,
    lng,
    lang: convertLocaleForBackend(lang),
    type: EGeocodingType.REVERSE_GEOCODE,
  };
  const res = await apiCached.get<GeocodingResponseDef>(
    "/crafthunts/geocoding",
    {
      id: JSON.stringify(params),
      params,
      headers: {
        "x-access-token": ENV.BACKEND_API_KEY,
        Authorization: "",
      },
    }
  );
  return res.data;
};

export const geocode = async (
  address: string,
  lang: string
): Promise<GeocodingResponseDef> => {
  const params = {
    address,
    lang: convertLocaleForBackend(lang),
    type: EGeocodingType.GEOCODE,
  };
  const res = await apiCached.get<GeocodingResponseDef>(
    "/crafthunts/geocoding",
    {
      id: JSON.stringify(params),
      params,
      headers: {
        "x-access-token": ENV.BACKEND_API_KEY,
        Authorization: "",
      },
    }
  );
  return res.data;
};
