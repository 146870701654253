import { ConditionalWrapper } from "@crafthunt-ui/ConditionalWrapper";
import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import { Markdown } from "@crafthunt-ui/Markdown/Markdown";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import { storyblokEditable } from "@storyblok/react";
import { useLocalStorageState } from "ahooks";
import clsx from "clsx";
import { EColors } from "constants/colors.constants";
import { EJourneyTypes } from "constants/journey.constants";
import { StoryblokBlokDef, StoryblokLinkDef } from "types/storyblok.types";
import { getTranslatedSlug } from "utils/router.helper";

export type BannerBlokDef = StoryblokBlokDef<{
  text: string;
  backgroundColor?: EColors;
  linkJourney?: EJourneyTypes;
  link?: StoryblokLinkDef;
}>;

type BannerProps = {
  blok: BannerBlokDef;
  dismissible?: boolean;
  inline?: boolean;
  onClick?: () => void;
};

export const Banner = ({
  blok,
  dismissible = true,
  inline = false,
  onClick,
}: BannerProps) => {
  const [hide, setHide] = useLocalStorageState<boolean | undefined>(
    `banner-hide-${blok._uid}`
  );

  if (hide) {
    return null;
  }

  const bgColorVariants = {
    [EColors.WHITE]: "bg-white text-black",
    [EColors.GRAY]: "bg-gray-100 text-black",
    [EColors.DARK_BLUE]: "bg-gray-900 text-white",
    [EColors.AMBER]: "bg-primary-600 text-white",
  };
  const textUnderlineColorVariants = {
    [EColors.WHITE]: "from-black to-black",
    [EColors.GRAY]: "from-black to-black",
    [EColors.DARK_BLUE]: "from-white to-white",
    [EColors.AMBER]: "from-white to-white",
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className={clsx(
        bgColorVariants[blok.backgroundColor || EColors.AMBER],
        "flex items-center justify-center",
        "relative min-h-9",
        dismissible ? "pl-2 pr-11 sm:pl-11" : "px-2",
        inline && "rounded-xl",
        typeof onClick === "function" && "group cursor-pointer"
      )}
      onClick={onClick}
    >
      <ConditionalWrapper
        condition={!!blok.link}
        wrapper={(children) => (
          <CustomLink
            href={getTranslatedSlug(blok.link)}
            target={blok.link?.target}
            className="group"
          >
            {children}
          </CustomLink>
        )}
      >
        <span
          className={clsx(
            "bg-gradient-to-r bg-[length:0%_1px] bg-left-bottom bg-no-repeat text-center transition-all duration-500 ease-out group-hover:bg-[length:100%_1px]",
            textUnderlineColorVariants[blok.backgroundColor || EColors.AMBER]
          )}
        >
          <Markdown openLinksInNewTab>{blok.text}</Markdown>
        </span>
      </ConditionalWrapper>
      {dismissible && (
        <div className="absolute right-2 top-1/2 -translate-y-1/2">
          <button
            type="button"
            className="p-1.5 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-white focus-visible:outline-offset-[-4px]"
            onClick={() => setHide(true)}
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon aria-hidden="true" className="size-5" />
          </button>
        </div>
      )}
    </div>
  );
};
