import { Image } from "@crafthunt-ui/Image";
import { ClockIcon, EyeIcon } from "@heroicons/react/24/outline";
import { ScreenSizes } from "constants/breakpoints";
import { EColors } from "constants/colors.constants";
import { interpolation } from "features/translations/helpers/translation.helper";
import { useTranslation } from "next-i18next";
import toast from "react-hot-toast";
import { MdIosShare } from "react-icons/md";
import { StoryblokBlogDef, StoryblokSettingsDef } from "types/storyblok.types";
import { triggerAnalyticsEvent, triggerShareBlog } from "utils/analytics";
import { DisplayDateFormats, formatDate } from "utils/date.helper";
import { Banner } from "../Banner";
import LandCTA from "../LandCTA";
import { Text } from "../Text/Text";

type BlogProps = {
  blok: StoryblokBlogDef;
  settings: StoryblokSettingsDef;
  locale: string;
  isBlogTranslated: boolean;
};

const Blog = ({ blok, settings, locale, isBlogTranslated }: BlogProps) => {
  const { t } = useTranslation();

  const shareBlog = () => {
    const currentURL = window.location.href;
    triggerShareBlog(currentURL);
    try {
      navigator.clipboard.writeText(currentURL);
      toast.success(t("Link copied!"));
    } catch {
      toast.error(`${t("Could not copy link")}: ${currentURL}`, {
        duration: 10 * 1000,
      });
    }
  };

  const requestTranslation = () => {
    toast.success(t("thank-you"));
    triggerAnalyticsEvent("blog_request_translation", {
      locale,
      blogTitle: blok.name,
    });
  };

  const categories = (blok.contentCategories ?? [])
    .slice(0, 3)
    .map((category) => t(category));

  return (
    <article className="overflow-hidden bg-white">
      {!isBlogTranslated && (
        <div className="mx-auto -mb-8 mt-8 max-w-4xl">
          <Banner
            blok={{
              _uid: "blog-banner",
              backgroundColor: EColors.GRAY,
              text: t("blog-not-translated"),
            }}
            dismissible={false}
            inline
            onClick={requestTranslation}
          />
        </div>
      )}
      <header>
        <div className="mx-auto max-w-4xl border-b border-gray-200 px-8 pb-10 pt-20">
          {categories.length > 0 && (
            <div className="mb-4 flex flex-wrap gap-2">
              {categories.map((category) => (
                <span
                  key={category}
                  className="inline-flex select-none items-center whitespace-nowrap rounded-lg bg-mobileBlueLight px-3.5 py-2 text-sm font-medium leading-tight text-white shadow-md"
                >
                  {category}
                </span>
              ))}
            </div>
          )}
          <h1 className="text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
            {blok.name}
          </h1>
          <div className="flex items-end justify-between">
            <div className="relative mt-8 flex items-center gap-x-4">
              {!!blok.authorImage?.filename && (
                <div className="relative size-16 flex-none sm:size-20">
                  <Image
                    className="rounded-full object-contain"
                    src={blok.authorImage.filename}
                    storyblokFocusPoint={blok.authorImage.focus}
                    storyblokSize="200x0"
                    alt={blok.author}
                    sizes="10vw"
                  />
                </div>
              )}
              <div>
                <div className="font-medium">{blok.author}</div>
                <div className="text-sm text-gray-600">{blok.authorRole}</div>
              </div>
            </div>
            <div
              onClick={shareBlog}
              className="cursor-pointer text-sm text-gray-600 hover:text-primary-600"
            >
              <MdIosShare className="mx-auto h-10 w-10 p-2" />
              {t("blog-share")}
            </div>
          </div>
        </div>
        <div className="mx-auto flex max-w-4xl flex-col gap-2 px-8 py-5 text-sm text-gray-600 sm:flex-row sm:gap-4">
          {!!blok.publishedAt && (
            <span className="flex items-center gap-2">
              <ClockIcon className="h-4 w-4" />
              {interpolation(t("blog-published-on"), [
                formatDate(blok.publishedAt, locale, DisplayDateFormats.D_M_Y),
              ])}
            </span>
          )}
          {!!blok.readingTime && (
            <span className="flex items-center gap-2">
              <EyeIcon className="h-4 w-4" />
              {interpolation(t("blog-reading-time"), [blok.readingTime])}
            </span>
          )}
        </div>
      </header>
      {/* Content Section */}
      <div className="relative py-4 sm:py-10">
        <div
          className="relative mx-auto"
          style={{ maxWidth: ScreenSizes.MEDIUM }}
        >
          {!!blok.intro && (
            <div className="px-8">
              <p className="mt-8 text-xl leading-8 text-gray-500">
                {blok.intro}
              </p>
            </div>
          )}
          <Text
            blok={{
              type: blok.bodyType === "richtext" ? "richtext" : "markdown",
              markdown: blok.body,
              richtext: blok.richtext,
            }}
            settings={settings}
            locale={locale}
          />
          {/* <div className='mt-6 prose prose-primary prose-lg font-semibold text-gray-700 mx-auto lg:px-6'>
                      If you like what you read, share it 🙂
                      <div className='prose grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-5'>
                          {navigation.map((item) => (
                              <button
                                  key={item.name}
                                  type='button'
                                  href={item.href}
                                  className={`inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-base font-medium rounded-sm text-white`}
                              >
                                  <item.icon className='h-6 w-6 mr-2' aria-hidden='true' />
                                  {item.name}
                              </button>
                          ))}
                      </div>
                  </div> */}
        </div>
      </div>
      {/* CTA Section */}
      {blok.showEmailCTA &&
        !!settings.bottomCta?.length &&
        settings.bottomCta.map((blok) => (
          <LandCTA key={blok._uid} settings={settings} />
        ))}
    </article>
  );
};

export default Blog;

// const navigation = [
//     {
//         name: 'Facebook',
//         href: 'https://www.facebook.com/sharer/sharer.php?u=${currentURL}',
//         color: 'blue',
//         icon: (props) => (
//             <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
//                 <path
//                     fillRule='evenodd'
//                     d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
//                     clipRule='evenodd'
//                 />
//             </svg>
//         ),
//     },
//     {
//         name: 'Instagram',
//         href: '#',
//         color: 'gray',
//         icon: (props) => (
//             <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
//                 <path
//                     fillRule='evenodd'
//                     d='M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z'
//                     clipRule='evenodd'
//                 />
//             </svg>
//         ),
//     },
//     {
//         name: 'Twitter',
//         href: 'https://twitter.com/intent/tweet?text=${intro}(${currentURL})',
//         color: 'gray',
//         icon: (props) => (
//             <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
//                 <path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
//             </svg>
//         ),
//     },
//     {
//         name: 'GitHub',
//         href: '#',
//         color: 'gray',
//         icon: (props) => (
//             <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
//                 <path
//                     fillRule='evenodd'
//                     d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
//                     clipRule='evenodd'
//                 />
//             </svg>
//         ),
//     },
//     {
//         name: 'E-Mail',
//         href: 'mailto:?subject=${name}&body=${intro}(${currentURL})',
//         color: 'gray',
//         icon: (props) => (
//             <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
//                 <path
//                     fillRule='evenodd'
//                     d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
//                     clipRule='evenodd'
//                 />
//             </svg>
//         ),
//     },
//     {
//         name: 'LinkedIn',
//         href: 'https://www.linkedin.com/shareArticle?mini=true&url=${currentURL}&title=${name}&summary=${intro}&source=${currentURL}',
//         color: 'gray',
//         icon: (props) => (
//             <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
//                 <path
//                     fillRule='evenodd'
//                     d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
//                     clipRule='evenodd'
//                 />
//             </svg>
//         ),
//     },
// ]
