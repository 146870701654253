import { AuthCTAButton } from "@auth/components/AuthCTAButton/AuthCTAButton";
import { AuthCTAButtonDef } from "@auth/types/auth-cta-button.types";
import { Image } from "@crafthunt-ui/Image";
import clsx from "clsx";
import Link from "next/link";
import { CSSProperties } from "react";
import { ImageDef } from "types/image.types";
import { StoryblokCTADef } from "types/storyblok.types";
import { getTranslatedSlug } from "utils/router.helper";

type AboutUsHeroProps = {
  blok: {
    title: string;
    description: string;
    image: ImageDef;
    cta?: (StoryblokCTADef | AuthCTAButtonDef)[];
  };
  className?: string;
  style?: CSSProperties;
};

export const AboutUsHero = ({
  blok: { title, description, image, cta },
  className,
  style,
}: AboutUsHeroProps) => {
  const ctaLink = cta?.length && cta[0];

  return (
    <div className={clsx("relative bg-gray-100", className)} style={style}>
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pb-10 pt-6 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-32 lg:pt-12 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            {!!ctaLink && (
              <div className="hidden sm:mt-32 sm:flex lg:mt-16">
                <div className="flex">
                  {"title" in ctaLink && (
                    <Link
                      href={getTranslatedSlug(ctaLink.link)}
                      className="rounded-sm bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                    >
                      {ctaLink.title} <span aria-hidden="true">&rarr;</span>
                    </Link>
                  )}
                  {"text" in ctaLink && (
                    <div className="mt-10 flex">
                      <AuthCTAButton blok={ctaLink} />
                    </div>
                  )}
                </div>
              </div>
            )}
            {!!title && (
              <h1 className="mt-24 text-2xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-5xl">
                {title}
              </h1>
            )}
            {!!description && (
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {description}
              </p>
            )}
          </div>
        </div>
        {!!image?.filename && (
          <div className="aspect-h-2 aspect-w-3 relative xl:aspect-none lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
            <Image
              className="h-full w-full object-cover"
              src={image?.filename}
              storyblokFocusPoint={image?.focus}
              storyblokQuality={100}
              alt="about us"
              priority
              sizes="(max-width: 1023px) 100vw, (max-width: 1279px) 40vw, 50vw"
            />
          </div>
        )}
      </div>
    </div>
  );
};
