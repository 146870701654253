import LanguageSelector from "@crafthunt-ui/LanguageSelector/LanguageSelector";
import { Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useResponsive } from "ahooks";
import Logo from "assets/crafthunt.svg";
import clsx from "clsx";
import { TranslatedSlugDef } from "features/translations/types/translation.types";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, ReactNode } from "react";
import { removeEmpty } from "utils/helpers";

const DynamicBanner = dynamic(
  () => import("components/sections/Banner").then((mod) => mod.Banner),
  {
    ssr: false,
  }
);

export type NavigationItem = {
  id: string;
  link: string;
  label: string;
};

export type BaseNavigationProps = {
  className?: string;
  leftMenu?: NavigationItem[];
  rightMenu?: ReactNode;
  hideHamburgerMenu?: boolean;
  disableLogoLink?: boolean;
  banners?: ReactNode[];
} & (
  | {
      showLanguagePicker?: boolean;
      translatedSlugs?: TranslatedSlugDef[];
    }
  | {
      showLanguagePicker: boolean;
      translatedSlugs: TranslatedSlugDef[];
    }
) &
  (
    | {
        showLanguageDisclaimerBanner?: boolean;
        locale?: string;
      }
    | {
        showLanguageDisclaimerBanner: boolean;
        locale: string;
      }
  );

const DISCLAIMER_LANGUAGES = ["ro", "pl", "fr", "es", "it", "cs", "el"];

/** Also update the `_variables.scss` */
export const NAVIGATION_HEIGHT = 72;

export const BaseNavigation = ({
  leftMenu,
  rightMenu,
  showLanguagePicker,
  translatedSlugs,
  showLanguageDisclaimerBanner,
  locale,
  className,
  hideHamburgerMenu,
  disableLogoLink,
  banners,
}: BaseNavigationProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const responsive = useResponsive();

  const filteredQueriesForLink = removeEmpty({
    ...router.query,
    slug: undefined,
    step: undefined,
    email: undefined,
    phone: undefined,
    id: undefined,
    use: undefined,
    channelId: undefined,
    page: undefined,
    from: undefined,
  });

  return (
    <div className={clsx(className, "sticky top-0 z-50 shadow-md")}>
      <div className="relative">
        {showLanguageDisclaimerBanner &&
          locale &&
          DISCLAIMER_LANGUAGES.includes(locale) && (
            <DynamicBanner
              blok={{
                _uid: `language-disclaimer-${locale}`,
                text: !responsive?.md
                  ? t("language-disclaimer-mobile")
                  : t("language-disclaimer"),
              }}
            />
          )}
        {banners}
        <Popover as="header" className="relative">
          <div
            className="flex items-center bg-gray-900"
            style={{ height: NAVIGATION_HEIGHT }}
          >
            <nav
              className="relative mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8"
              aria-label="Global"
            >
              <div className="flex flex-1 items-center">
                <div className="flex w-full items-center justify-between md:w-auto">
                  {disableLogoLink ? (
                    <Logo className="h-6 w-auto sm:h-7" />
                  ) : (
                    <Link
                      id="navigation-home"
                      href={{
                        pathname: "/",
                        query: filteredQueriesForLink,
                      }}
                    >
                      <span className="sr-only">Crafthunt</span>
                      <Logo className="h-6 w-auto sm:h-7" />
                    </Link>
                  )}
                  {!hideHamburgerMenu && (
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button
                        id="navigation-open-menu"
                        className="focus-ring-inset inline-flex items-center justify-center rounded-sm bg-gray-900 p-2 text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white"
                      >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  )}
                </div>
                {!!leftMenu?.length && (
                  <div className="hidden space-x-8 md:ml-10 md:flex">
                    {leftMenu.map((item) => (
                      <Link
                        key={item.id}
                        id={item.id}
                        href={{
                          pathname: item.link,
                          query: filteredQueriesForLink,
                        }}
                        className="text-sm font-medium text-white hover:text-gray-300"
                      >
                        {item.label}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
              {(!!rightMenu || showLanguagePicker) && (
                <div
                  className={clsx("z-50 md:flex md:items-center md:space-x-4", {
                    "hidden md:block": !hideHamburgerMenu,
                  })}
                >
                  {rightMenu}
                  {showLanguagePicker && (
                    <div
                      className={clsx({
                        "hidden md:block": !!rightMenu && hideHamburgerMenu,
                      })}
                    >
                      <LanguageSelector
                        translatedSlugs={translatedSlugs}
                        compressed
                      />
                    </div>
                  )}
                </div>
              )}
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute inset-x-0 top-0 z-50 origin-top p-2 transition md:hidden"
            >
              {({ close }) => (
                <div className="rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                  <div className="flex items-center justify-between px-5 pt-4">
                    <div>
                      <Logo className="h-8 w-auto" />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button
                        id="navigation-close-menu"
                        className="inline-flex items-center justify-center rounded-sm bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600"
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="pb-6 pt-5">
                    {!!leftMenu?.length && (
                      <div className="space-y-2 px-2">
                        {leftMenu.map((item) => (
                          <Link
                            key={item.id}
                            id={item.id}
                            href={{
                              pathname: item.link,
                              query: filteredQueriesForLink,
                            }}
                            className="flex items-center justify-between rounded-sm px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                            onClick={() => close()}
                          >
                            {item.label}
                            <ChevronRightIcon className="size-5" />
                          </Link>
                        ))}
                      </div>
                    )}
                    {!!rightMenu && (
                      <div className="my-2 flex flex-col space-y-3 border-b border-t px-2 py-4 sm:block sm:space-y-0">
                        {rightMenu}
                      </div>
                    )}
                    {showLanguagePicker && (
                      <div className="z-50 mt-4 flex justify-end px-5">
                        <LanguageSelector translatedSlugs={translatedSlugs} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </div>
  );
};
