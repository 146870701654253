// These are the only img types that our firebase resize extensions supports
const RESIZABLE_IMGS_REGEX = /\.(gif|jpe?g|tiff?|png|webp)\?/i;

export enum FirebaseImageSizeEnum {
  FULL = "",
  XLARGE = "_2000x2000",
  LARGE = "_1280x1280",
  MEDIUM = "_800x800",
  SMALL = "_400x400",
  TINY = "_200x200",
  AVATAR = "_80x80",
}

export const getResizedImageUrl = (
  imageUrl: string,
  imageSize: FirebaseImageSizeEnum
): string => {
  if (!imageUrl || !imageUrl.includes("firebasestorage")) {
    return imageUrl;
  }
  const indexOfFileFormat = imageUrl.search(RESIZABLE_IMGS_REGEX);
  if (indexOfFileFormat != -1) {
    return `${imageUrl.slice(0, indexOfFileFormat)}${imageSize}${imageUrl.slice(
      indexOfFileFormat
    )}`;
  }
  return imageUrl;
};

export const transformImage = ({
  src,
  storyblokSize = "1500x0",
  storyblokFocusPoint,
  storyblokQuality = 80,
  storyblokAdditionalFilters = "",
  firebaseImageSize: firebaseImageSize = FirebaseImageSizeEnum.MEDIUM,
}: {
  src: string;
  storyblokSize?: string;
  storyblokFocusPoint?: string;
  storyblokQuality?: number;
  storyblokAdditionalFilters?: string;
  firebaseImageSize?: FirebaseImageSizeEnum;
}) => {
  if (!src) return "";
  if (!storyblokSize) return src;

  // if SVG, then return image
  if (src.indexOf(".svg") !== -1) return src;

  if (src.indexOf("storyblok.com") !== -1) {
    const imageService = "https://img2.storyblok.com/";

    const nonFilteredPath =
      src.indexOf("https") > -1
        ? "https://a.storyblok.com"
        : "//a.storyblok.com";
    const path = src.replace(nonFilteredPath, "");
    const focal = storyblokFocusPoint ? `:focal(${storyblokFocusPoint})` : "";
    return (
      imageService +
      storyblokSize +
      `/filters:quality(${storyblokQuality})${storyblokAdditionalFilters}${focal}` +
      path
    );
  }

  if (src.indexOf("firebasestorage") !== -1) {
    return getResizedImageUrl(src, firebaseImageSize);
  }

  return src;
};
