import { AuthCTAButton } from "@auth/components/AuthCTAButton/AuthCTAButton";
import { AuthCTAButtonDef } from "@auth/types/auth-cta-button.types";
import clsx from "clsx";
import Link from "next/link";
import { CSSProperties } from "react";
import { ImageDef } from "types/image.types";
import { StoryblokCTADef } from "types/storyblok.types";
import { getTranslatedSlug } from "utils/router.helper";
import { Image } from "../ui/Image";

type AboutUsContentProps = {
  blok: {
    title: string;
    subtitle: string;
    description: string;
    cta?: (StoryblokCTADef | AuthCTAButtonDef)[];
    images?: ImageDef[];
  };
  className?: string;
  style?: CSSProperties;
};

export const AboutUsContent = ({
  blok: { title, subtitle, description, cta, images },
  className,
  style,
}: AboutUsContentProps) => {
  const ctaLink = cta?.length && cta[0];
  const [image1, image2, image3, image4] = images || [];

  return (
    <div
      className={clsx("overflow-hidden bg-white py-32", className)}
      style={style}
    >
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            {!!title && (
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h2>
            )}
            {!!subtitle && (
              <p className="mt-6 text-xl leading-8 text-gray-600">{subtitle}</p>
            )}
            {!!description && (
              <p className="mt-6 text-base leading-7 text-gray-600">
                {description}
              </p>
            )}
            {!!ctaLink && (
              <div className="mt-10 flex">
                {"title" in ctaLink && (
                  <Link
                    href={getTranslatedSlug(ctaLink.link)}
                    className="rounded-sm bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                  >
                    {ctaLink.title} <span aria-hidden="true">&rarr;</span>
                  </Link>
                )}
                {"text" in ctaLink && <AuthCTAButton blok={ctaLink} />}
              </div>
            )}
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            {!!image1?.filename && (
              <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                <div className="aspect-h-5 aspect-w-7 relative w-148 max-w-none rounded-lg bg-gray-50">
                  <Image
                    className="h-full w-full rounded-lg object-cover"
                    src={image1.filename}
                    alt="about us"
                    priority
                    storyblokFocusPoint={image1.focus}
                    storyblokQuality={100}
                    sizes="(max-width: 1023px) 100vw, (max-width: 1279px) 40vw, 50vw"
                  />
                </div>
              </div>
            )}
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-148 lg:items-start lg:justify-end lg:gap-x-8">
              {!!image2?.filename && (
                <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                  <div className="aspect-h-3 aspect-w-4 relative w-96 max-w-none flex-none rounded-lg bg-gray-50">
                    <Image
                      className="h-full w-full rounded-lg object-cover"
                      src={image2.filename}
                      alt="about us"
                      priority
                      storyblokFocusPoint={image2.focus}
                      storyblokQuality={100}
                      sizes="(max-width: 1023px) 100vw, (max-width: 1279px) 40vw, 50vw"
                    />
                  </div>
                </div>
              )}
              {!!image3?.filename && (
                <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                  <div className="aspect-h-5 aspect-w-7 relative w-148 max-w-none flex-none rounded-lg bg-gray-50">
                    <Image
                      className="h-full w-full rounded-lg object-cover"
                      src={image3.filename}
                      alt="about us"
                      priority
                      storyblokFocusPoint={image3.focus}
                      storyblokQuality={100}
                      sizes="(max-width: 1023px) 100vw, (max-width: 1279px) 40vw, 50vw"
                    />
                  </div>
                </div>
              )}
              {!!image4?.filename && (
                <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                  <div className="aspect-h-3 aspect-w-4 relative w-96 max-w-none rounded-lg bg-gray-50">
                    <Image
                      className="h-full w-full rounded-lg object-cover"
                      src={image4.filename}
                      alt="about us"
                      priority
                      storyblokFocusPoint={image4.focus}
                      storyblokQuality={100}
                      sizes="(max-width: 1023px) 100vw, (max-width: 1279px) 40vw, 50vw"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
