import { Image } from "@crafthunt-ui/Image";
import { SectionHeader } from "@crafthunt-ui/SectionHeader";
import clsx from "clsx";

const LandFeatureScreenshot = ({
  blok,
  className = undefined,
  style = undefined,
}) => {
  return (
    <div
      className={clsx(
        "relative bg-gray-50 pb-4 pt-16 sm:pt-24 lg:pb-12 lg:pt-32",
        className
      )}
      style={style}
    >
      <div className="relative mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <div>
          <SectionHeader
            label={blok.title}
            description={blok.description}
            content={blok.content}
          />
        </div>
        <div className="relative mt-12 h-80 lg:h-96">
          <Image
            className="rounded-lg object-cover object-top shadow-xl"
            src={blok.image.filename}
            storyblokFocusPoint={blok.image.focus}
            alt="landing page feature screenshot"
            sizes="(max-width: 1300px) 100vw, 80vw"
          />
        </div>
      </div>
    </div>
  );
};

export default LandFeatureScreenshot;
