import { Image, ImageFallbackEnum } from "@crafthunt-ui/Image";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { forwardRef } from "react";
import { ImageDef } from "types/image.types";
import { DisplayDateFormats, formatDate } from "utils/date.helper";
import { CustomLink } from "./CustomLink/CustomLink";
import { Loading } from "./Loading/Loading";

export type BlogCardProps = {
  id?: string;
  postImage?: string;
  postImagePriority?: boolean;
  postImageFallbackType?: ImageFallbackEnum;
  postTitle: string | JSX.Element;
  postPreview: string;
  postDate?: string;
  postLength?: string;
  postLink: string;
  category?: string;
  author?: string;
  authorImage?: ImageDef;
  hideFooter?: boolean;
  locale?: string;
  showButton?: boolean;
  className?: string;
  onClick?: () => void;
};

export const BlogCard = forwardRef<HTMLAnchorElement, BlogCardProps>(
  function BlogCard(
    {
      id,
      postImage,
      postImagePriority,
      postImageFallbackType,
      postTitle,
      postPreview,
      postDate,
      postLength,
      postLink,
      category,
      author,
      authorImage,
      hideFooter,
      locale,
      showButton,
      className,
      onClick,
    },
    ref
  ) {
    const { t } = useTranslation();
    return (
      <CustomLink
        id={id}
        onClick={onClick}
        href={postLink}
        className={clsx(
          "group flex flex-col overflow-hidden rounded-sm shadow-lg hover:shadow-xl",
          className
        )}
        ref={ref}
      >
        {(!!postImage || postImageFallbackType) && (
          <div className="aspect-w-3 relative h-48 shrink-0 bg-mobileBlueLight">
            <Image
              className="object-cover group-hover:opacity-75"
              src={postImage}
              alt="blog post image"
              sizes="(max-width: 767px) 90vw, (max-width: 1200px) 30vw, 20vw"
              priority={postImagePriority}
              fallbackType={postImageFallbackType}
            />
          </div>
        )}
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="line-clamp-1 text-sm font-medium text-primary-600">
              {category}
            </p>

            <p className="line-clamp-2 text-xl font-semibold text-gray-900">
              {postTitle}
            </p>
            <p className="mt-3 line-clamp-3 text-base text-gray-500">
              {postPreview}
            </p>
          </div>
          {author && !hideFooter && (
            <div className="mt-6 flex items-center">
              {!!authorImage?.filename && (
                <div className="shrink-0">
                  <Image
                    className="h-10 w-10 rounded-full"
                    src={authorImage.filename}
                    storyblokSize="200x0"
                    storyblokFocusPoint={authorImage.focus}
                    alt={author}
                    width={40}
                    height={40}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </div>
              )}
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900">{author}</p>
                {!!postDate && (
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime={postDate}>
                      {formatDate(postDate, locale, DisplayDateFormats.D_M_Y)}
                    </time>
                    <span aria-hidden="true">&middot;</span>
                    <span>
                      {postLength} {t("minutes-to-read")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
          {showButton && (
            <div className="mt-6">
              <div className="flex w-full items-center rounded-sm bg-white px-3.5 py-2.5 text-base font-semibold text-primary-600 shadow-sm ring-1 ring-inset ring-primary-600 hover:bg-primary-600 hover:text-white">
                <p>{t("more-info-label")}</p>
              </div>
            </div>
          )}
        </div>
      </CustomLink>
    );
  }
);

type BlogCardSkeletonProps = {
  className?: string;
};

export const BlogCardSkeleton = ({ className }: BlogCardSkeletonProps) => {
  return (
    <Loading isSkeleton className={clsx(className, "h-112")}>
      <div className="aspect-h-2 aspect-w-3 bg-gray-200" />
      <div className="absolute left-0 top-0 h-48 w-full bg-gray-300" />
      <div className="absolute left-6 top-52 my-1.5 mr-2 h-7 w-8/12 rounded-full bg-gray-300 px-3 py-0.5" />
      <div className="absolute left-6 top-64 my-1.5 mr-2 h-4 w-10/12 rounded-full bg-gray-300 px-3 py-0.5" />
      <div className="absolute left-6 top-72 my-1.5 mr-2 h-4 w-10/12 rounded-full bg-gray-300 px-3 py-0.5" />
      <div className="absolute left-6 top-80 my-1.5 mr-2 h-4 w-10/12 rounded-full bg-gray-300 px-3 py-0.5" />
    </Loading>
  );
};
