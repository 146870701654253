import { Image } from "@crafthunt-ui/Image";
import clsx from "clsx";
import { CSSProperties } from "react";
import { ImageDef } from "types/image.types";

type ImageSectionProps = {
  blok: {
    image: ImageDef;
    bgColor: string;
  };
  className?: string;
  style?: CSSProperties;
};

export const ImageSection = ({
  blok: { image, bgColor },
  className,
  style,
}: ImageSectionProps) => {
  const bgColorVariants = {
    white: "bg-white",
    "gray-100": "bg-gray-100",
  };

  return (
    <div
      className={clsx(
        bgColorVariants[bgColor || "white"],
        "py-24 sm:py-32",
        className
      )}
      style={style}
    >
      <div className="xl:mx-auto xl:max-w-7xl xl:px-8">
        {!!image?.filename && (
          <div className="aspect-h-2 aspect-w-5 relative w-full xl:rounded-lg">
            <Image
              className="h-full w-full object-cover xl:rounded-lg"
              src={image.filename}
              storyblokFocusPoint={image.focus}
              storyblokQuality={100}
              alt="image section"
              priority
              sizes="(max-width: 1023px) 100vw, (max-width: 1279px) 40vw, 50vw"
            />
          </div>
        )}
      </div>
    </div>
  );
};
