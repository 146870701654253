import { useAuth } from "@auth/context/AuthContext";
import LayoutSimple from "@crafthunt-layout/LayoutSimple/LayoutSimple";
import LoadingScreen from "@crafthunt-ui/LoadingScreen/LoadingScreen";
import { ISbStoriesParams, getStoryblokApi } from "@storyblok/react";
import { serverSideTranslationsWithLocale } from "@translations/helpers/server-side-translations.helper";
import BauGPT from "components/sections/BauGPT";
import { isDev } from "constants/env";
import { TranslatedSlugDef } from "features/translations/types/translation.types";
import { GetStaticProps, InferGetServerSidePropsType } from "next";
import { useRouter } from "next/router";
import { getFullSlug } from "utils/router.helper";

const DEFAULT_FOLDER_SLUG = "baugpt";

export default function Page({
  slug,
  locale,
  locales,
  settings,
}: InferGetServerSidePropsType<typeof getStaticProps>) {
  const { accessToken } = useAuth();
  const router = useRouter();
  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  if (router.isFallback) {
    return <LoadingScreen />;
  }

  // get default slug and translated slugs from story
  const translatedSlugs: TranslatedSlugDef[] = locales.map((lang) => ({
    path: getFullSlug(slug, DEFAULT_FOLDER_SLUG),
    lang,
  }));

  return (
    <LayoutSimple
      meta={{
        title: "BauGPT",
        twitter_title: "BauGPT",
      }}
      locale={locale}
      translatedSlugs={translatedSlugs}
      isFullscreen
      settings={settings}
    >
      <BauGPT key={accessToken || "baugpt-anonymous-user"} locale={locale} />
    </LayoutSimple>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale, locales }) => {
  const storyblokApi = getStoryblokApi();
  const sbParams: ISbStoriesParams = {
    version: "published",
    language: locale,
    resolve_links: "url",
  };

  if (isDev) {
    sbParams.version = "draft";
  }

  let settings;

  try {
    // Fetch Global settings
    const settingsData = await storyblokApi.get(
      `cdn/stories/land/settings`,
      sbParams
    );
    settings = settingsData?.data?.story?.content || null;
  } catch (err) {
    console.error("[storyblok]", err.response?.data);
    return { notFound: true };
  }

  return {
    props: {
      slug: DEFAULT_FOLDER_SLUG,
      locale,
      locales,
      settings,
      ...(await serverSideTranslationsWithLocale(locale)),
    },
  };
};
