import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { CountryFlag } from "components/icons/CountryFlag";
import { LANGUAGES, getLangName } from "constants/languages";
import { TranslatedSlugDef } from "features/translations/types/translation.types";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { LanguageOptionDef } from "types/language.types";
import { triggerAnalyticsEvent } from "utils/analytics";
import { removeEmpty } from "utils/helpers";
import { convertLocaleForAnalytics } from "utils/language.helper";

type LanguageSelectorProps = {
  translatedSlugs: TranslatedSlugDef[];
  compressed?: boolean;
};

const LanguageSelector = ({
  translatedSlugs,
  compressed,
}: LanguageSelectorProps) => {
  const router = useRouter();
  const [selected, setSelected] = useState<LanguageOptionDef>(LANGUAGES[0]);

  useEffect(() => {
    if (router.locale) {
      setSelected(
        LANGUAGES.find((language) => language.languageCode === router.locale)
      );
    }
  }, [router.locale]);

  const handleLanguageChange = (lang: string) => {
    if (selected.languageCode === lang) {
      return;
    }
    triggerAnalyticsEvent("language_change", {
      language: convertLocaleForAnalytics(lang),
    });
    if (translatedSlugs?.length) {
      const foundSlug = translatedSlugs.find((x) => x.lang === lang);
      if (foundSlug) {
        router.push(
          {
            pathname: foundSlug.path,
            query: removeEmpty({
              ...router.query,
              slug: undefined,
            }),
          },
          null,
          {
            locale: lang,
          }
        );
      }
    } else {
      const rootUrl = `/${lang !== "de" ? lang : ""}`;
      router.push(
        {
          pathname: rootUrl,
          query: removeEmpty({
            ...router.query,
            slug: undefined,
          }),
        },
        null,
        {
          locale: lang,
        }
      );
    }

    const newLang = LANGUAGES.find(
      (language) => language.languageCode === lang
    );
    setSelected(newLang);
  };

  return (
    <div className="flex items-center">
      <Listbox value={router.locale} onChange={handleLanguageChange}>
        {({ open }) => (
          <div className="relative inline-block">
            <Listbox.Button
              id="language-selector"
              className={clsx(
                "relative w-full cursor-pointer rounded-sm bg-white text-left text-sm shadow-sm hover:opacity-90",
                compressed
                  ? "h-9 min-w-9 p-2"
                  : "border border-gray-300 py-2 pl-3 pr-10 md:w-40"
              )}
            >
              <span className="flex items-center">
                <div className="shrink-0 text-xl">
                  <CountryFlag
                    countryCode={selected.countryCode}
                    className="w-5 shadow-flag"
                  />
                </div>
                {!compressed && (
                  <span className="ml-3 block truncate capitalize">
                    {getLangName(selected.languageCode, selected.languageCode)}
                  </span>
                )}
              </span>
              {!compressed && (
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              )}
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute right-0 z-50 mt-2 h-56 w-56 origin-top-right divide-y divide-gray-100 overflow-auto rounded-sm bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm md:h-auto"
                style={{
                  maxHeight: "80vh",
                }}
              >
                {LANGUAGES.map((option) => (
                  <Listbox.Option
                    key={option.languageCode}
                    id={`language-${option.languageCode}`}
                    className={({ active }) =>
                      clsx(
                        active ? "bg-primary-600 text-white" : "text-gray-900",
                        "relative cursor-pointer select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={option.languageCode}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <div className="shrink-0 text-lg">
                            <CountryFlag
                              countryCode={option.countryCode}
                              className="w-5 shadow-flag"
                            />
                          </div>
                          <span
                            className={clsx(
                              "ml-3 block truncate capitalize",
                              selected ? "font-semibold" : "font-normal"
                            )}
                          >
                            {getLangName(
                              option.languageCode,
                              option.languageCode
                            )}
                          </span>
                        </div>

                        {!!selected && (
                          <span
                            className={clsx(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
};

export default LanguageSelector;
