import { Image } from "@crafthunt-ui/Image";
import clsx from "clsx";
import { CSSProperties } from "react";
import { ImageDef } from "types/image.types";
import { StoryblokLinkDef } from "types/storyblok.types";
import { getTranslatedSlug } from "utils/router.helper";

type TeamMemberDef = {
  name: string;
  role: string;
  description?: string;
  image?: ImageDef;
  linkedin?: StoryblokLinkDef;
};

type StatsSectionProps = {
  blok: {
    title?: string;
    description?: string;
    members?: TeamMemberDef[];
  };
  className?: string;
  style?: CSSProperties;
};

export const TeamSection = ({
  blok: { title, description, members },
  className,
  style,
}: StatsSectionProps) => {
  return (
    <div className={clsx("bg-white py-32", className)} style={style}>
      <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
        <div className="mx-auto max-w-2xl">
          {!!title && (
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {title}
            </h2>
          )}
          {!!description && (
            <p className="mt-4 text-lg leading-8 text-gray-600">
              {description}
            </p>
          )}
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          {members.map((person) => (
            <li key={person.name}>
              {!!person.image?.filename && (
                <div className="relative mx-auto h-56 w-56 rounded-full">
                  <Image
                    className="h-full w-full rounded-full object-cover"
                    src={person.image.filename}
                    storyblokFocusPoint={person.image.focus}
                    storyblokQuality={100}
                    alt={person.name}
                    priority
                    sizes="(max-width: 1023px) 100vw, (max-width: 1279px) 40vw, 50vw"
                  />
                </div>
              )}
              <h3 className="mt-6 text-lg font-semibold leading-7 tracking-tight text-gray-900">
                {person.name}
              </h3>
              <p className="text-base leading-6 text-gray-600">{person.role}</p>
              {!!description && (
                <p className="mx-auto w-3/5 text-base leading-6 text-gray-500">
                  {person.description}
                </p>
              )}
              {!!person.linkedin?.url && (
                <ul role="list" className="mt-6 flex justify-center gap-x-6">
                  <li>
                    <a
                      href={getTranslatedSlug(person.linkedin)}
                      className="text-gray-400 hover:text-gray-500"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="sr-only">LinkedIn</span>
                      <svg
                        className="h-5 w-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
