import { useEffect } from "react";

export const Typeform = ({
  blok,
}: {
  blok: {
    _uid: string;
    formId: string;
  };
}) => {
  const blokId = `typeform-${blok._uid}`;
  const formId = blok.formId;

  useEffect(() => {
    // Dynamically load the typeform script
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.type = "text/javascript";
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [formId]);

  return <div id={blokId} data-tf-live={formId}></div>;
};
