import { AuthCTAButton } from "@auth/components/AuthCTAButton/AuthCTAButton";
import { AuthCTAButtonDef } from "@auth/types/auth-cta-button.types";
import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import { Image } from "@crafthunt-ui/Image";
import clsx from "clsx";
import { EJourneyTypes } from "constants/journey.constants";
import Link from "next/link";
import { useRouter } from "next/router";
import { CSSProperties } from "react";
import { StoryblokLinkDef } from "types/storyblok.types";
import { removeEmpty } from "utils/helpers";
import { getTranslatedSlug } from "utils/router.helper";

type ImageObj = {
  id: number;
  alt: string;
  name: string;
  focus: string;
  title: string;
  filename: string;
  copyright: string;
  fieldtype: string;
};

type TestimonialObj = {
  _uid: string;
  role: string;
  image: ImageObj;
  quote: string;
  author: string;
  profile_link: StoryblokLinkDef | null;
  _editable: string;
};

type TestimonialGridProps = {
  blok: {
    _uid: string;
    title: string | null;
    view_profile_button_text: string | null;
    hide_gradient_background: boolean;
    cta?: AuthCTAButtonDef[];
    cta_subtitle: string | null;
    cta_button_link: StoryblokLinkDef | null;
    cta_button_label: string | null;
    cta_button_journey?: EJourneyTypes;
    featured_testimonial: TestimonialObj[];
    small_testimonials: TestimonialObj[];
  };
  className?: string;
  style?: CSSProperties;
};

const TestimonialGrid = ({
  blok,
  className = undefined,
  style = undefined,
}: TestimonialGridProps) => {
  const router = useRouter();

  const convertToArrayOfColumns = (
    inputArray: TestimonialObj[]
  ): TestimonialObj[][][] => {
    const firstColumn: TestimonialObj[] = [];
    const secondColumn: TestimonialObj[] = [];
    const thirdColumn: TestimonialObj[] = [];
    const fourthColumn: TestimonialObj[] = [];

    inputArray.forEach((item, index) => {
      const columnNumber = index % 4;
      if (columnNumber === 0) {
        firstColumn.push(item);
      } else if (columnNumber === 1) {
        fourthColumn.push(item);
      } else if (columnNumber === 2) {
        secondColumn.push(item);
      } else {
        thirdColumn.push(item);
      }
    });

    return [
      [firstColumn, secondColumn],
      [thirdColumn, fourthColumn],
    ];
  };
  const grid_items = convertToArrayOfColumns(blok.small_testimonials);

  const firstFeaturedTestimonial = blok.featured_testimonial?.length
    ? blok.featured_testimonial[0]
    : null;

  const authCta = blok.cta?.length ? blok.cta[0] : undefined;

  return (
    <div
      className={clsx(
        "relative isolate bg-gray-50 pb-32 pt-24 sm:pt-32",
        className
      )}
      style={style}
    >
      {!blok.hide_gradient_background && (
        <>
          <div
            className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
            aria-hidden="true"
          >
            <div
              className="bg-gradient-to-tr from-primary-500 to-primary-600"
              style={{
                aspectRatio: "1313/771",
                width: "82.0625rem",
                marginLeft: "max(50%,38rem)",
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
            aria-hidden="true"
          >
            <div
              className="-ml-96 flex-none origin-top-right rotate-45 bg-gradient-to-tr from-primary-500 to-primary-600 xl:ml-0"
              style={{
                aspectRatio: "1313/771",
                width: "82.0625rem",
                marginRight: "calc(50% - 12rem)",
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </>
      )}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {!!blok.title && (
          <div className="mx-auto max-w-xl text-center">
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {blok.title}
            </h2>
          </div>
        )}
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none xl:grid-flow-col xl:grid-cols-4">
          {firstFeaturedTestimonial && (
            <figure className="rounded-sm bg-white shadow-lg sm:col-span-2 xl:col-start-2 xl:row-end-1">
              <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
                <p>{`“${firstFeaturedTestimonial.quote}”`}</p>
              </blockquote>
              <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-500/10 px-6 py-4 sm:flex-nowrap">
                {!!firstFeaturedTestimonial.image?.filename && (
                  <div className="relative h-10 w-10 flex-none">
                    <Image
                      className="rounded-full bg-gray-50 object-contain"
                      src={firstFeaturedTestimonial.image.filename}
                      storyblokSize="200x200/smart"
                      storyblokFocusPoint={firstFeaturedTestimonial.image.focus}
                      alt="Featured testimonial author"
                    />
                  </div>
                )}
                <div className="flex-auto">
                  <div className="font-semibold">
                    {firstFeaturedTestimonial.author}
                  </div>
                  {firstFeaturedTestimonial.profile_link && (
                    <Link
                      href={getTranslatedSlug(
                        firstFeaturedTestimonial.profile_link
                      )}
                      className="text-gray-500 hover:text-gray-900"
                    >
                      {blok.view_profile_button_text}
                      <span className="pl-2" aria-hidden="true">
                        →
                      </span>
                    </Link>
                  )}
                </div>
              </figcaption>
            </figure>
          )}
          {grid_items.map((columnGroup, columnGroupIdx) => (
            <div
              key={columnGroupIdx}
              className="space-y-8 xl:contents xl:space-y-0"
            >
              {columnGroup.map((columnData, columnIdx) => {
                if (!columnData.length) {
                  return null;
                }
                const makeRowSpan =
                  (columnGroupIdx === 0 && columnIdx === 0) ||
                  (columnGroupIdx === 1 && columnIdx === 1);
                return (
                  <div
                    key={columnIdx}
                    className={clsx(
                      "space-y-8",
                      makeRowSpan ? "xl:row-span-2" : "xl:row-start-1"
                    )}
                  >
                    {columnData.map((testimonial) => (
                      <figure
                        key={testimonial._uid}
                        className="rounded-sm bg-white p-6 shadow-lg"
                      >
                        <blockquote className="text-gray-900">
                          <p>{`“${testimonial.quote}”`}</p>
                        </blockquote>
                        <figcaption className="mt-6 flex items-center gap-x-4">
                          {!!testimonial.image?.filename && (
                            <div className="relative h-10 w-10 flex-none">
                              <Image
                                className="rounded-full bg-gray-50 object-contain"
                                src={testimonial.image.filename}
                                storyblokSize="200x200/smart"
                                storyblokFocusPoint={testimonial.image.focus}
                                alt="Featured testimonial author"
                              />
                            </div>
                          )}
                          <div>
                            <div className="font-semibold">
                              {testimonial.author}
                            </div>
                            {testimonial.profile_link && (
                              <Link
                                href={{
                                  pathname: getTranslatedSlug(
                                    testimonial.profile_link
                                  ),
                                  query: removeEmpty({
                                    ...router.query,
                                    slug: undefined,
                                  }),
                                }}
                                className="text-gray-600 hover:text-gray-900"
                              >
                                {blok.view_profile_button_text}
                                <span className="pl-2" aria-hidden="true">
                                  →
                                </span>
                              </Link>
                            )}
                          </div>
                        </figcaption>
                      </figure>
                    ))}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        {/* Call to Action Section */}
        {(!!blok.cta_subtitle || !!authCta || !!blok.cta_button_label) && (
          <div className="mx-auto mt-10 max-w-2xl text-center">
            {!!blok.cta_subtitle && (
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-700">
                {blok.cta_subtitle}
              </p>
            )}
            {(!!authCta || !!blok.cta_button_label) && (
              <div className="mt-8 flex items-center justify-center">
                {!!authCta && <AuthCTAButton blok={authCta} />}

                {!authCta && !!blok.cta_button_label && (
                  <CustomLink
                    isCTA
                    id={`cta-testimonial-${blok._uid}`}
                    href={getTranslatedSlug(blok.cta_button_link, {
                      fromJourney: blok.cta_button_journey,
                    })}
                    target={blok.cta_button_link?.target}
                    className="text-md rounded-sm bg-primary-600 px-5 py-3 font-medium text-white shadow-md hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {blok.cta_button_label}
                  </CustomLink>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TestimonialGrid;
