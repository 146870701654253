import clsx from "clsx";
import NextImage, { ImageProps as NextImageProps } from "next/image";
import { useState } from "react";
import { MdImageNotSupported } from "react-icons/md";
import { FirebaseImageSizeEnum, transformImage } from "utils/image.helper";
import { EmptyStateCompanies } from "../icons/empty-state/EmptyStateCompanies";
import { EmptyStateCraftsmen } from "../icons/empty-state/EmptyStateCraftsmen";

export enum ImageFallbackEnum {
  DEFAULT = "default",
  CRAFTSMAN = "craftsman",
  COMPANY = "company",
}

type ImageProps = {
  src?: string | null;
  storyblokSize?: string;
  storyblokFocusPoint?: string;
  storyblokQuality?: number;
  storyblokAdditionalFilters?: string;
  firebaseImageSize?: FirebaseImageSizeEnum;
  fallbackType?: ImageFallbackEnum;
} & Omit<NextImageProps, "src" | "layout">;

export const Image = ({
  src,
  storyblokSize = "1500x0",
  storyblokFocusPoint = "",
  storyblokQuality = 80,
  storyblokAdditionalFilters = "",
  firebaseImageSize = FirebaseImageSizeEnum.MEDIUM,
  className,
  fallbackType = ImageFallbackEnum.DEFAULT,
  onLoadingComplete,
  sizes,
  fill = true,
  priority,
  width,
  height,
  ...rest
}: ImageProps) => {
  const [error, setError] = useState(false);
  const [tryOriginalSrc, setTryOriginalSrc] = useState(false);

  const handleOnError = () => {
    if (tryOriginalSrc) {
      setError(true);
    } else {
      setTryOriginalSrc(true);
    }
  };

  if (!src || error) {
    return (
      <div
        className={clsx(className, "flex items-center justify-center")}
        {...rest}
      >
        {
          {
            [ImageFallbackEnum.CRAFTSMAN]: (
              <EmptyStateCraftsmen className="h-24 w-24" />
            ),
            [ImageFallbackEnum.COMPANY]: (
              <EmptyStateCompanies className="h-24 w-24" />
            ),
            [ImageFallbackEnum.DEFAULT]: (
              <MdImageNotSupported className="h-6 w-6" />
            ),
          }[fallbackType]
        }
      </div>
    );
  }
  return (
    <NextImage
      className={clsx(className)}
      src={
        tryOriginalSrc
          ? src
          : transformImage({
              src,
              storyblokSize: storyblokSize,
              storyblokQuality: storyblokQuality,
              storyblokFocusPoint: storyblokFocusPoint,
              storyblokAdditionalFilters: storyblokAdditionalFilters,
              firebaseImageSize,
            })
      }
      onError={handleOnError}
      onLoadingComplete={onLoadingComplete}
      sizes={sizes || "100vw"}
      fill={fill && !width && !height}
      priority={priority}
      quality={storyblokQuality}
      width={width}
      height={height}
      {...rest}
    />
  );
};
