import { AuthCTAButton } from "@auth/components/AuthCTAButton/AuthCTAButton";
import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import { Image } from "@crafthunt-ui/Image";
import { Markdown } from "@crafthunt-ui/Markdown/Markdown";
import { StarsRating } from "@crafthunt-ui/StarsRating";
import { storyblokEditable } from "@storyblok/react";
import clsx from "clsx";
import { getTranslatedSlug } from "utils/router.helper";
import { resolveIcon } from "../LandFeatures/components/SmallFeature";

const CTAFeatureScreenshot = ({
  blok,
  className = undefined,
  style = undefined,
}) => {
  const buttons = (
    <>
      {blok.cta?.map((ctaBlok) => (
        <div key={ctaBlok._uid}>
          <AuthCTAButton blok={ctaBlok} />
        </div>
      ))}
      {!!blok.buttonLabel && (
        <CustomLink
          isCTA
          id={`cta-feature-screenshot-${blok._uid}`}
          href={getTranslatedSlug(blok.buttonLink, {
            fromJourney: blok.buttonJourney,
          })}
          target={blok.buttonLink?.target}
          className="text-md inline-block rounded-sm bg-primary-600 px-3.5 py-4 font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
        >
          {blok.buttonLabel}
        </CustomLink>
      )}
      {Boolean(blok.rating) && (
        <div className="mt-4 flex items-center gap-2 font-medium text-white">
          <StarsRating rating={parseFloat(blok.rating)} /> {blok.ratingText}
        </div>
      )}
    </>
  );

  if (blok.variant === "variant1") {
    return (
      <div
        className={clsx("overflow-hidden bg-gray-900", {
          "py-8 pb-12 sm:py-16": blok.verticalPadding === "small",
          "py-12 sm:py-24": blok.verticalPadding === "medium",
          "py-12 sm:py-32":
            !blok.verticalPadding || blok.verticalPadding === "large",
        })}
        {...storyblokEditable(blok)}
      >
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                {blok.preheader && (
                  <h2 className="text-base font-semibold uppercase tracking-wider text-primary-500">
                    {blok.preheader}
                  </h2>
                )}
                <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                  {blok.header}
                </p>
                {blok.description && (
                  <div className="mt-6 text-lg/8 text-gray-300">
                    <Markdown>{blok.description}</Markdown>
                  </div>
                )}
                <dl className="mt-10 max-w-xl space-y-8 text-base/7 text-gray-300 lg:max-w-none">
                  {blok.steps.map((step) => {
                    const Icon =
                      resolveIcon[step.icon] ?? resolveIcon.lightbulb;

                    return (
                      <div key={step._uid} className="relative pl-9">
                        <dt className="inline font-semibold text-white">
                          <Icon
                            aria-hidden="true"
                            className="absolute left-1 top-1 size-5 text-primary-500"
                          />
                          {step.header ?? step.name}
                        </dt>{" "}
                        <dd className="inline">{step.description}</dd>
                      </div>
                    );
                  })}
                </dl>
                <div className="mt-12 pl-9">{buttons}</div>
              </div>
            </div>
            <div
              className={clsx(
                "aspect-h-3 aspect-w-4 relative w-[48rem] max-w-none sm:w-[57rem] md:-ml-4 lg:-ml-0",
                {
                  "hidden sm:block": blok.hideImageOnMobile,
                }
              )}
            >
              <Image
                className="rounded-xl object-cover shadow-xl ring-1 ring-white/10"
                src={blok.image.filename}
                storyblokSize="0x2000"
                storyblokFocusPoint={blok.image.focus}
                alt=""
                sizes="(max-width: 1500px) 50vw, 30vw"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "bg-white",
        {
          "py-0 xl:py-8": blok.verticalPadding === "small",
          "py-0 xl:py-10": blok.verticalPadding === "medium",
          "py-0 xl:py-12":
            !blok.verticalPadding || blok.verticalPadding === "large",
        },
        className
      )}
      style={style}
      {...storyblokEditable(blok)}
    >
      <div className="mx-auto max-w-7xl">
        <div
          className={clsx(
            "relative overflow-hidden bg-gray-900 px-6 shadow-xl sm:rounded-2xl sm:px-6 md:px-8 lg:px-16",
            {
              "py-12 sm:py-16": blok.verticalPadding === "small",
              "py-16 sm:py-20": blok.verticalPadding === "medium",
              "py-20 sm:py-24":
                !blok.verticalPadding || blok.verticalPadding === "large",
            }
          )}
        >
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8">
                <div className="lg:max-w-lg">
                  <h2 className="text-base font-semibold uppercase tracking-wider text-primary-500">
                    {blok.preheader}
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                    {blok.header}
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                    <div className="flow-root">
                      <ul role="list" className="-mb-8">
                        {blok.steps.map((step, stepIndex) => (
                          <li key={step._uid}>
                            <div className="relative pb-8">
                              {stepIndex !== blok.steps.length - 1 ? (
                                <span
                                  className="absolute left-5 top-4 -ml-px h-full w-0.5 bg-gray-400"
                                  aria-hidden="true"
                                />
                              ) : null}
                              <div className="relative flex space-x-3">
                                <div>
                                  <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-3xl bg-primary-600 text-white">
                                      {stepIndex + 1}
                                    </div>
                                  </dt>
                                </div>
                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                  <div>
                                    <p className="tracking-tight text-white">
                                      <span className="font-bold tracking-wide">
                                        {step.header}
                                      </span>
                                      <span className="mt-2 block tracking-wide text-gray-300">
                                        {step.description}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                      <div className="mt-12 pl-12">{buttons}</div>
                    </div>
                  </dl>
                </div>
              </div>
              {!!blok.image?.filename && (
                <div className="relative ml-10 w-[57rem] rounded-xl shadow-xl">
                  <Image
                    className="hidden h-full w-full rounded-xl object-cover shadow-xl lg:block"
                    src={blok.image.filename}
                    storyblokSize="0x1000"
                    storyblokFocusPoint={blok.image.focus}
                    alt="Product screenshot"
                    sizes="(max-width: 1500px) 50vw, 30vw"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTAFeatureScreenshot;
